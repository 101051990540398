<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authFackMethods } from "@/state/helpers";
// import { tableData } from "./sample";
// import moment from 'moment'

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Loan List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      // tableData: tableData,
      list:[],
      loading: false,
      title: "Loan List",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Loan",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "loan_status",
      sortDesc: false,
      fields: [
        { index: 'No' },
        {
          key: "account_no",
          sortable: true,
        },
        {
          key: "created_at",
          sortable: true,
        },
        {
          key: "loan_amount",
          sortable: true,
        },
        {
          key: "customer_name",
          sortable: true,
        },
        {
          key: "loan_collector",
          sortable: true,
        },
        {
          key: "handle_by",
          sortable: true,
        },
        {
          key: "loan_status",
          sortable: true,
        },
        {
          key: 'operation'
        }
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.list.length;
    },
    compute_user(){
      return this.$store.state.authfack.user
    },
    lock_viewBtn(){
      if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('042') || this.compute_user.permissions.includes('044')){
        return false
      }
      else{
        return true
      }
    },
    flag_showList(){
      if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('045')){
        return true
      }
      else{
        return false
      }
    },
  },
  mounted() {
    // let self = this
    // Set the initial number of items
    // this.totalRows = this.items.length;
    if(this.flag_showList){
      this.loading = true
      const baseURI = 'loans/list'
      // let user = JSON.parse(localStorage.getItem('user'));
      let formData = new FormData();
      // if(!this.compute_user.permissions.includes('*')){
      //   formData.append('user_uuid', user.id);
      // }

      this.$http.post(baseURI,formData)
      .then((result) => {
        if(result.data.status == 'success'){
          console.log(result.data.data)

          // let abc = result.data.data
          // abc.forEach(function(item){
          //   console.log('pubo',item.customer.name)
          //   // abc.customer_name = (item.customer.name).trim() 
          // })


          // console.log(abc)
          // setTimeout(function(){
            this.list = result.data.data
            this.loading = false
          // },500)
          
        }
      })
      .catch((err) => {
        if(err == 'session_timeout'){
          this.$notify({
            group: 'auth',
            title: 'User Authentication',
            text: 'Your session has expired, please login again',
            type: 'warn'
          });
          this.logout();
          this.$router.push({
            path: "/login",
          });
        }
      })
    }
    
  },
  methods: {
    ...authFackMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    add(){
      this.$router.push("/loan/0");
    },
    view(loan_id){
      this.$router.push(`/loan/${loan_id}`);
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
      <b-overlay :show="loading" rounded="sm">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Loan List
              <div style="float:right"><b-button size="sm" variant="primary" @click="add">Add Loan<i class="uil uil-plus ms-2"></i></b-button></div>
            </h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="list"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(customer_name)="data">
                {{ data.item.customer.name }}
              </template>

              <template #cell(created_at)="data">
                {{ moment(data.item.created_at).format('YYYY-MM-DD') }}
              </template>
              <template #cell(handle_by)="data">
                 {{ data.item.handle_by.name }}
              </template>

              <!-- A virtual composite column -->
              <template #cell(operation)="data">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <a
                      v-show="!lock_viewBtn"
                      href="javascript:void(0);"
                      class="px-2 text-primary"
                      v-b-tooltip
                      title="Edit"
                      @click="view(data.item.uuid)"
                    >
                      <i class="uil uil-pen font-size-18"></i>
                    </a>
                  </li>
                  <!-- <li class="list-inline-item">
                    <a
                      href="javascript:void(0);"
                      v-bind:class="[data.item.status =='active' ? 'text-danger' : 'text-success', 'px-2' ]"
                      v-b-tooltip
                      :title="data.item.status =='active' ? 'Lock' : 'Redo' "
                      @click="toggleStatus(data.item.status)"
                    >
                      <i 
                      v-bind:class="[data.item.status =='active' ? 'uil-file-lock-alt' : 'uil-file-redo-alt', 'uil', 'font-size-18']"
                      ></i>
                    </a>
                  </li> -->
                </ul>
              </template>

              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-start"
                >
                  <p><b>Total : {{rows}}</b></p>
                </div>
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
